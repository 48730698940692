import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import data from "../../../core/static/goal/motivation.json";
import useJsonParse from "../../../hooks/useJsonParse";
import CheckboxCard from "../../UI/checkboxCard/CheckboxCard";
import ContinueButton from "../../UI/continueButton/ContinueButton";

import styles from "./Motivation.module.scss";

const Motivation = props => {
    const { nextStep } = props;
    const [cards, setCards] = useState();
    const [activeCards, setActiveCards] = useState([]);
    const [disableButton, setDisableButton] = useState(true);
    const parsedData = useJsonParse(data);

    const selectCard = card => {
        if(card.checked) {
            setActiveCards((prev => [...prev, card]))
        } else {
            const removeCardIndex = activeCards.findIndex(el => el.id === card.id);
            setActiveCards([...activeCards.slice(0, removeCardIndex), ...activeCards.slice(removeCardIndex + 1)])
        }
    }

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_motivation_appeared');
    }, [])

    useEffect(() => {
        console.log(activeCards)
        if(activeCards.length !== 0) {
            setDisableButton(false);
        } else {
            console.log('no')
            setDisableButton(true);
        }
    }, [activeCards])
    
    useEffect(() => {
        setCards(parsedData);
    }, [parsedData]);

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_motivation_tapped', {answer: activeCards.map(card => { return card.title})});
        const answer = new Map();
        answer.set("key", "motivation");
        answer.set("value", activeCards.map(card => { return card.title}));
        nextStep(answer);
    }

    return (
        <>
            <div className={`${styles.mainContainer} flex-column`}>
                <h1 className={styles.cardTitle}>What motivates you the most?</h1>
                <span className={`${styles.marginTitle} componentSubtitle`}>This will help us find the best program for you.</span>
                <div className={`flex-column cardsContainer`}>
                    <div className={`flex-column cardsWrapper`}>
                        { cards?.map(card => ( <CheckboxCard key={card.id} data={card} selectCard={selectCard}/> )) }
                    </div>
                    <ContinueButton nextStep={clickContinue} disableButton={disableButton}/>
                </div>
            </div>
        </>
    )
}   

export default Motivation;