import styles from "./PaymentRateCards.module.scss";
import RateCard from "../rateCard/RateCard";
import GetButton from "../getProgramButton/GetButton";
import RefundBox from "../refundBox/RefundBox";

const PaymentRateCards = props => {
    const {
        textButton,
        nextPage,
        rateCards,
        activeRate,
        setActiveRateCard,
        isAEuCase,
        subdomain,
        eventInfo,
        discount
    } = props;
    return (
       <>
           <div className={`${styles.cardsContainer} flex-column`}>
               {
                   rateCards.map(card =>
                       <RateCard
                           key={card.id}
                           activeCard={activeRate}
                           setActiveCard={setActiveRateCard}
                           subdomain={subdomain}
                           eventInfo={eventInfo}
                           card={card}
                           discount={discount}
                       />)
               }
           </div>
           { isAEuCase ? (<RefundBox />) : null }
           <GetButton text={textButton} nextPage={nextPage}/>
       </>
      )
  }

export default PaymentRateCards;
