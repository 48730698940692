import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import eventData from "../../../core/static/eventData.json";

import styles from "./GoalBanner.module.scss";

const GoalBanner = props => {
    const { nextStep, subdomain } = props;

    const eventInfo = eventData[subdomain].goalBanner;

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_one_million_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_one_million_continue_tapped');
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            { subdomain === 'ny' &&
                (<img className={styles.absoluteBackground} src="/images/goals/banner-background-up-ny.png" alt="" />)
            }
            <img className={styles.logoImage} src="/images/Logo.svg" alt="" />
            <h1 className={styles.title}>1 million people</h1>
            <p className={styles.subtitle}> have chosen Everdance</p>
            <img className={`${styles.imageBanner}`} src={eventInfo.imageBanner} style={eventInfo.cardsImageStyle} alt="" />
            <ContinueButton text={'Continue'} nextStep={clickContinue}/>
        </div>
    )
}   

export default GoalBanner;