import {useEffect} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./AboutResult.module.scss";
import eventData from "../../../core/static/eventData.json";

const AboutResult = props => {
    const { nextStep, subdomain } = props;
    const ageInfo = localStorage.getItem('ageInfo');
    const targetWeight = localStorage.getItem('targetWeight');
    const variantB = subdomain === 'b' || subdomain === 'h' || subdomain === 'bf' || subdomain === 'ny';
    const eventInfo = eventData[subdomain].aboutResult;

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_section_about_you_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_section_about_you_tapped');
        nextStep();
    }

    return (
        <>
            { subdomain === 'bf' &&
                (<img className={styles.absoluteImage} src="/images/result/about-result-image-bf.png" alt="" />)
            }
            { subdomain === 'ny' &&
                (<>
                    <img className={`${styles.absoluteBackground} ${styles.absoluteBackgroundLeft}`} src="/images/result/about-result-background-left-ny.png" alt="" />
                    <img className={`${styles.absoluteBackground} ${styles.absoluteBackgroundRight}`} src="/images/result/about-result-background-right-ny.png" alt="" />
                    <img className={styles.absoluteImageNy} src="/images/result/about-result-image-ny.png" alt="" />
                </>)
            }
            <div className={`${styles.mainContainer} flex-column`}>
                <ResultHeader url={'/images/result/about-result.jpg'}/>
                { variantB ? (
                    <div className={`${styles.bVariantResult} flex-column`}>
                        <img className={styles.image} src={eventInfo.image} alt="" />
                        <h1>Women in their {ageInfo}</h1>
                        <p className={`${styles.text} resultSubtitle`}>
                            Who aim to weight <b>{targetWeight}</b>, remember: care & attention should become an integral
                            part of their daily workouts. Don't forget about the health and harmony of your body!
                        </p>
                    </div>
                    ) : (
                        <>
                            <h1>We Got You!</h1>
                            <span className={`${styles.subtitle} componentSubtitle`}>
                                <span className={styles.percentage}>88,6%</span> of users report positive changes
                                <b>after 1-week</b> of use.
                            </span>
                            <img className={styles.image} src="/images/result/about-image.jpg" alt="" />
                            <p className={`${styles.text} resultSubtitle`}>Let's learn more about your lifestyle</p>
                        </>
                    )
                }
                <div className="buttonContainer">
                    <ContinueButton nextStep={clickContinue}/>
                </div>
            </div>
        </>
    )
}   

export default AboutResult;