import { useEffect, useState } from 'react';

import { Routes, Route, useLocation } from 'react-router-dom';
import { GrowthBookProvider, GrowthBook } from "@growthbook/growthbook-react";

import amplitude from 'amplitude-js';

import ReactPixel from 'react-facebook-pixel';

import queryString from 'query-string';

import AgeGroup from "./pages/ageGroup/AgeGroup";
import Payment from './pages/payment/Payment';
import CreateAccount from "./pages/createAccount/CreateAccount";
import Failure from './pages/paymentFailure/PaymentFailure';
import Paywall from './pages/paywall/Paywall';
import Email from './pages/email/Email';
import { RecurlyPaymentForm } from './pages/payment/RecurlyPaymentForm';
import StepsComponent from "./pages/stepper/StepsComponent";

import React from 'react';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import Intro from "./pages/intro/Intro";
import basenames from "./core/static/basenames.json";

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-tbi5NRjBnDAf7aK",
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key
    });
    }
});

function App() {
    const location = useLocation();
    const queryParams = queryString.parse(localStorage.getItem('search'));
    const [content, setContent] = useState(basenames);

    const userProperties = {
        utmSource: queryParams.utm_source,
        utmMedium: queryParams.utm_medium,
        utmCampaign: queryParams.utm_campaign,
        utmContent: queryParams.utm_content
    };

    const amplitudeOnInit = (instance) => {
        // instance.logEvent('origin', {value: localStorage.getItem('origin')});
        // instance.logEvent('search', {value: localStorage.getItem('search')});
    };

    amplitude.getInstance().onInit(amplitudeOnInit);
    const initializeAmplitude = amplitude.getInstance().init('0f240f05cd42b2b601303fa61d325c37');
    if(localStorage.getItem('user_id') !== null && localStorage.getItem('user_id') !== undefined ){
        amplitude.getInstance().setUserId(localStorage.getItem('user_id'));
    } else{
        const v4Id = uuidv4();
        localStorage.setItem('user_id', v4Id);
        amplitude.getInstance().setUserId(v4Id);
    }
    amplitude.getInstance().setUserProperties(userProperties);

    useEffect(() => {
        console.log("n");
        localStorage.removeItem('popup');
        localStorage.removeItem('gift');
        const id = Date.now();
        document.cookie = `id=${id}`;
        ReactPixel.init('239948538703054', {external_id: id});

        //amplitude.getInstance().onInit(amplitudeOnInit);
        //amplitude.getInstance().setUserProperties(userProperties);

        growthbook.loadFeatures();
        growthbook.setAttributes({
            "id": new Date(),
            "loggedIn": true,
            "employee": true
        });
    }, []);

    let l = window.location;
    let array = l.pathname.split('/');
    let urlParts = l.host.split(".")
    let subdomain = urlParts.length >= 3 ? l.host.split(".")[0] : ''
    let basename = 'eu';
    if(array.indexOf("us") > -1) {
        basename = 'us';
    } else if(array.indexOf("lam") > -1) {
        basename = 'lam';
    } else if(array.indexOf("bfa") > -1) {
        basename = 'bfa';
    } else if(array.indexOf("bf") > -1) {
        basename = 'bf';
    } else if(array.indexOf("ny1") > -1) {
        basename = 'ny1';
    } else if(array.indexOf("ny") > -1) {
        basename = 'ny';
    } else if(array.indexOf("k") > -1) {
        basename = 'k';
    }
    if(subdomain && content[subdomain].indexOf(basename) === -1)
    {
        window.location.replace(l.href.replace(`${l.origin}/${basename}`, `${l.origin}/eu`))
    }
    localStorage.setItem('subdomain', subdomain === 'test' ? '' : subdomain)
    localStorage.setItem('liveMode', subdomain !== 'test');
    let pathValue;
    if(l.hash !== null && l.hash !== undefined && l.hash !== ""){
        let index = array.indexOf(basename)
        if (index !== -1) {
            array.splice(index, 1);
        }
        pathValue = array.slice(1, array.length + 1)[0];
    }
    amplitude.getInstance().setUserProperties({"subdomain": subdomain});
    const isIntroPage = ["b", "h", "bf", "ny", "test"].indexOf(subdomain) > -1

    return pathValue === "paywall" ? (
        <div className="App">
           <Routes>
             <Route path='/' element={<GrowthBookProvider growthbook={growthbook}>
                                        <Paywall />
                                        </GrowthBookProvider>}
             />
             <Route path="steps" element={<StepsComponent />} />
             <Route path="paywall" element={
               <GrowthBookProvider growthbook={growthbook}>
                 <Paywall />
               </GrowthBookProvider>}
             />
             <Route path="payment" element={<Payment />} />
//             <Route path="payment" element={<RecurlyProvider publicKey="ewr1-I0ZlpZw9YDHMXDZF0ykIVz">
//                                                  <Elements>
//                                                    <RecurlyPaymentForm />
//                                                  </Elements>
//                                                </RecurlyProvider>} />
             <Route path="registration" element={<CreateAccount />} />
             <Route path="failure" element={<Failure />} />
           </Routes>
         </div>
    ) : pathValue === "payment" ? (
        <div className="App">
          <Routes>
            <Route path='/' element={<Payment />}
            />
            <Route path="steps" element={<StepsComponent />} />
            <Route path="email" element={<Email />} />
            <Route path="paywall" element={
              <GrowthBookProvider growthbook={growthbook}>
                <Paywall />
              </GrowthBookProvider>}
            />
            <Route path="payment" element={<Payment />} />
            <Route path="registration" element={<CreateAccount />} />
            <Route path="failure" element={<Failure />} />
          </Routes>
        </div>
    ) : (
        <div className="App">
            <Routes>
              <Route path='/' element={
                <GrowthBookProvider growthbook={growthbook}>
                    { isIntroPage ? (<Intro />) : (<AgeGroup />) }
                </GrowthBookProvider>}
              />
              <Route path="steps" element={<StepsComponent />} />
              <Route path="paywall" element={
                <GrowthBookProvider growthbook={growthbook}>
                  <Paywall />
                </GrowthBookProvider>}
              />
              <Route path="payment" element={<Payment />} />
              <Route path="registration" element={<CreateAccount />} />
              <Route path="failure" element={<Failure />} />
            </Routes>
          </div>
        );
}

export default App;
