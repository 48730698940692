import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./LifestyleResult.module.scss";

const LifestyleResult = props => {
    const { nextStep, subdomain } = props;
    const variantB = subdomain === 'b' || subdomain === 'h' || subdomain === 'bf' || subdomain === 'ny';

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_section_lifestyle_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_section_lifestyle_tapped');
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <ResultHeader url={'/images/result/lifestyle-result.jpg'}/>
            {
                variantB ? (
                    <>
                        <h1 className={styles.commentTitle}>Sticking to a plan can be hard. Everdance makes it easy.</h1>
                        <div className={`${styles.comment} flex-row`}>
                            <div className={styles.commentImageBox}>
                                <img className={styles.commentImage} src="/images/result/lifestyle-result-image.png" alt="" />
                            </div>
                            <div className={`${styles.commentArea} flex-column`}>
                                <img src="/images/stars.svg" alt="" />
                                <p className={styles.commentText}>
                                    “Everdance made dancing my daily routine and saved me time on going to workouts.
                                    I can now train from home and prioritize self-care.”
                                </p>
                                <p className={styles.commentAuthor}>
                                    - Ashley
                                </p>
                            </div>
                        </div>
                        <span className={styles.commentHint}>
                            *Everdance users report positive changes
                             <span className={styles.hintBold}> after 1-week </span>
                            of use.
                        </span>
                        <ContinueButton text={'Continue'} nextStep={clickContinue}/>
                    </>
                ) : (
                    <>
                        <h1 className={styles.title}>Everdance was made for people just like you!</h1>
                        <img className={styles.image} src="/images/result/lifestyle-image.jpg" alt="" />
                        <p className={`${styles.text} resultSubtitle`}><span>83%</span> of Everdance users</p>
                        <span className={`${styles.subtitle} componentSubtitle`}>claim that the dance plan we offer is easy to follow and makes it simple to stay on track.</span>
                        <ContinueButton text={'All right'} nextStep={clickContinue}/>
                    </>
                )
            }

        </div>
    )
}   

export default LifestyleResult;