import styles from "./MealPlanBonus.module.scss";
import { useRef } from "react";

import GetButton from "../getProgramButton/GetButton";
import CustomCheckbox from "../checkbox/Checkbox";
import MainGoal from "../../goal/mainGoal/MainGoal";

const MealPlanAdvantages = () => {
    const checkboxData = {
        "checked": true,
        "disabled": true
    }
    return (
        <div className={styles.mealPlanBonusAdvantages}>
            <div className={styles.mealPlanBonusAdvantagesGroup}>
                <div className={styles.mealPlanBonusAdvantage}>
                    <CustomCheckbox data={checkboxData} checkCard={()=>{}}/>
                    <div className={styles.advantagePoint}>
                        Full access to <br/> 350+ dance <br/> classes
                    </div>
                </div>
                <div className={styles.mealPlanBonusAdvantage}>
                    <CustomCheckbox data={checkboxData} checkCard={()=>{}}/>
                    <div className={styles.advantagePoint}>
                        Feedback from <br/> dance instructors
                    </div>
                </div>
            </div>
            <div className={styles.mealPlanBonusAdvantagesGroup}>
                <div className={styles.mealPlanBonusAdvantage}>
                    <CustomCheckbox data={checkboxData} checkCard={()=>{}}/>
                    <div className={styles.advantagePoint}>
                        Just 15 minutes per <br/> day to see <br/> noticeable results
                    </div>
                </div>
                <div className={styles.mealPlanBonusAdvantage}>
                    <CustomCheckbox data={checkboxData} checkCard={()=>{}}/>
                    <div className={styles.advantagePoint}>
                        Personal <br/> dance plan
                    </div>
                </div>
            </div>
        </div>
    )
}

const MealPlanBoxImage = ({ subdomain }) => {
    switch(subdomain) {
        case "bf":
            return (<img className={styles.bFGiftImage} src="/images/paywall/black-friday-icon.png" alt="" />)
        case "ny":
            return (<></>)
        default:
            return (
                <div className={styles.giftImage}>
                    <img src="/images/paywall/icons/surprise-icon.png" alt="" />
                </div>
            )
    }
}

const MealPlanBonus = ({ subdomain }) => {
    const ref = useRef(null);
    const scrollTo = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const blackFridayCase = subdomain === 'bf'
    const newYearCase = subdomain === 'ny'
    const containerStyle =  blackFridayCase ? styles.bFContainerBox :
                            newYearCase ? styles.nYContainerBox : ""

    return (
        <div className={styles.mealPlanBonus}>
            <MealPlanAdvantages />
            <div className={styles.mealPlanBonusBox}>
                <div className={`${styles.mealPlanBonusContainer} ${containerStyle}`}>
                    { newYearCase && (
                        <img className={styles.boxBackgroundImage} src="/images/paywall/paywall-free-bonus-ny.png" alt="" />
                    )}
                    <div className={styles.mealPlanBonusContent}>
                        <MealPlanBoxImage subdomain={subdomain} />
                        <div className={styles.mealPlanBonusTitle}>
                            <h2>
                                <span className={styles.redText}>+ FREE BONUS:</span>
                                <br/>
                                Meal Plan &
                                <br/>
                                HIIT Workouts Program
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <GetButton text={'Continue'} nextPage={ scrollTo } />
            <div ref={ref} className={styles.mealPlanBonusRef} />
        </div>
      )
  }

export default MealPlanBonus;
