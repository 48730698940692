import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./GoalResult.module.scss";

const GoalResult = props => {
    const { nextStep, subdomain } = props;
    const variantB = subdomain === 'b' || subdomain === 'h' || subdomain === 'bf' || subdomain === 'ny';

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_section_goal_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_section_goal_tapped');
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <ResultHeader url={'/images/result/goal-result.jpg'}/>
            {
                variantB ? (
                    <>
                        <img className={styles.image} src="/images/result/goal-result-image.png" alt="" />
                        <div className={styles.commentContainer}>
                            <div className={styles.titleContainer}>
                                <h2>Thanks for changing my life</h2>
                                <img src="/images/stars.svg" alt="" />
                            </div>
                            <p>Guys, you're freaking amazing! I never would've thought that dancing could finally help me lose weight without any grueling workouts and giving me immense pleasure from new moves.</p>
                            <div className={`${styles.authorContainer} flex-between`}>
                                <span>Ashley Townsend</span>
                            </div>
                        </div>
                        <ContinueButton text={'Continue'} nextStep={clickContinue}/>
                    </>
                ) : (
                    <>
                        <h1>You’re in the right place!</h1>
                        <span className={`${styles.subtitle} componentSubtitle`}>Together we create a dancing journey, that will help you to achieve your goal.</span>
                        <img className={styles.image} src="/images/result/goal-image.jpg" alt="" />
                        <p className={`${styles.text} resultSubtitle`}>But first we need to know a little more about you.</p>
                        <ContinueButton text={'All right'} nextStep={clickContinue}/>
                    </>
                )
            }
        </div>
    )
}   

export default GoalResult;