import styles from "./StartDiscount.module.scss";

const StartDiscount = () => {
    return (
        <div className={styles.startTrialBox}>
            <div className={styles.startTrialContainer}>
                <div className={styles.startTrial}>
                   <div className={styles.discountInfo}>
                    <h1>
                      Previous discount:
                      <span className={styles.redTextPrev}>50%</span>
                    </h1>
                    <h2>
                        Start your personal plan with
                        <br/>
                        <span className={styles.redText}>60% discount</span>
                     </h2>
                   </div>
                    <div className={styles.giftImage}>
                        <img src="/images/gift_icon.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
      )
  }

export default StartDiscount;