import styles from "./ImageSaleAd.module.scss";

const ImageSaleAd = ({ subdomain, eventInfo }) => {
    const containerStyle = subdomain === 'bf' ? styles.bFContainer :
                           subdomain === 'ny' ? styles.nYContainer : ""

    return (
        <div className={`${styles.galleryImageSale} ${containerStyle}`}>
            <div className={`${styles.imageSaleAd} ${styles.imageSaleAdTop} flex-row`}>
                <div className={`${styles.imageSaleAdText} flex-row`}>
                    <span>{eventInfo.saleAdName}</span>
                    <img className={styles.imageSaleStar} src={eventInfo.saleAdImage} alt="" />
                    <span>SALE</span>
                </div>
                <div className={`${styles.imageSaleAdText} flex-row`}>
                    <span>{eventInfo.saleAdName}</span>
                    <img className={styles.imageSaleStar} src={eventInfo.saleAdImage} alt="" />
                    <span>SALE</span>
                </div>
            </div>
            <img className={styles.galleryImage} src="/images/paywall/a/paywall-a.gif" alt="" />
            <div className={`${styles.imageSaleAd} ${styles.imageSaleAdBottom} flex-row`}>
                <div className={`${styles.imageSaleAdText} flex-row`}>
                    <span>{eventInfo.saleAdName}</span>
                    <img className={styles.imageSaleStar} src={eventInfo.saleAdImage} alt="" />
                    <span>SALE</span>
                </div>
                <div className={`${styles.imageSaleAdText} flex-row`}>
                    <span>{eventInfo.saleAdName}</span>
                    <img className={styles.imageSaleStar} src={eventInfo.saleAdImage} alt="" />
                    <span>SALE</span>
                </div>
            </div>
        </div>
    )
}   

export default ImageSaleAd;