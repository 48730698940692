import { useEffect, useState } from "react";
import { useFeatureValue } from "@growthbook/growthbook-react";
import { useNavigate } from "react-router";

import amplitude from 'amplitude-js';
import ReactPixel from 'react-facebook-pixel';
import moment from 'moment/moment';
import sha256 from 'crypto-js/sha256';

import ImageCard from "../../components/UI/imageCard/ImageCard";
import useJsonParse from "../../hooks/useJsonParse";
import data from "../../core/static/ageGroup.json";

import styles from "./AgeGroup.module.scss";

const token = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';

const AgeGroup = () => {
    const [cards, setCards] = useState(useJsonParse(data, 'a'));
    const value = useFeatureValue("age-goal", "fallback");
    const [activeCard, setActiveCard] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('discountStatus');
        const eventId = `viewcontent.${getCookie('id')}`;
        ReactPixel.track('ViewContent', {eventID: eventId});
        fetch(`https://graph.facebook.com/v10.0/239948538703054/events?access_token=${token}`, {
            method: "POST",
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: [
                  {
                    event_name: "ViewContent",
                    event_time: moment().unix(),
                    action_source: "website",
                    event_id: eventId,
                    user_data: {
                        client_user_agent: navigator.userAgent,
                        external_id: sha256(getCookie('id')).toString()
                    }
                  }
                ]
            })
        })
    }, [])

    useEffect(() => {
        if(value === 'a' || value === 'b') {
            setCards(data[value])
            amplitude.getInstance().logEvent('screen_onboarding_age_group_appeared', { version: value});
        }
    }, [value])

    const selectCard = card => {
        setActiveCard(card);
        navigate('steps');
        amplitude.getInstance().logEvent('button_onboarding_age_group_tapped', { answer: card.title});
    }

    function getCookie(name) {
        const matches = document.cookie.match(new RegExp(
          "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    const subdomain = localStorage.getItem('subdomain');
    const variantA = subdomain === 'a';
    const variantB = subdomain === 'b';

    return (
        <div className={`${styles.ageGroupContainer} flex-column`}>
            <img src="/images/Logo.svg" alt="" />
            { variantA ? (
                <>
                    <div className={`${styles.starsContainer} flex-row`}>
                        <img className={styles.starsImage} src="/images/stars.svg" alt="" />
                        <h2 className={styles.rating}>4.5</h2>
                   </div>
                   <p className={styles.subtitleA}><b>1M+</b> downloads</p>
                   <p className={styles.downloadsTextA}>Choose your age and install the Everdance app</p>
                </>
            ) : (
                variantB ? (
                    <>
                        <div className={`${styles.starsContainer} flex-row`}>
                            <img className={styles.starsImage} src="/images/stars.svg" alt="" />
                            <h2 className={styles.rating}>4.5</h2>
                        </div>
                        <p className={styles.downloadsText}>Your personal dancing plan</p>
                        <p className={styles.subtitle}>According to your <b>age</b></p>
                    </>
                  ) : (
                      <>
                          <div className={`${styles.starsContainer} flex-row`}>
                              <img className={styles.starsImage} src="/images/stars.svg" alt="" />
                              <h2 className={styles.rating}>4.5</h2>
                          </div>
                          <p className={styles.downloadsText}>Your dancing plan for weight-loss</p>
                          <p className={styles.subtitle}>According to your <b>age</b></p>
                      </>
                )
            )}
            <div className={styles.gridContainer}>
                {cards?.map(card => (
                    <ImageCard key={card.id} data={card} selectCard={selectCard} />
                ))}
            </div>
        </div>
    )
}   

export default AgeGroup;