import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import amplitude from 'amplitude-js';

import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./PlanResult.module.scss";
import { useFeatureValue } from "@growthbook/growthbook-react";
import data from "../../../core/static/paywall.json";

const PlanResult = (props) => {
    const { subdomain, answers } = props;
    const value = useFeatureValue("paywall_testing", "fallback");
    const [tick, setTick] = useState(0);
    const [timer, setTimer] = useState();
    const [content, setContent] = useState(data.planResult);
    const navigate = useNavigate();
    const [slide, setSlide] = useState(0);
    const [slideTimer, setSlideTimer] = useState();
    const variantB = subdomain === 'b' || subdomain === 'h' || subdomain === 'bf' || subdomain === 'ny';

    useEffect(() => {
        setTimer(setInterval(() => setTick(prev => prev + 1), 60));
        amplitude.getInstance().logEvent(`screen_onboarding_creating_plan_${value}_appeared`);
        return () => {
            setTick(0);
            clearInterval(timer);
        }
    }, [])

    useEffect(() => {
        if(tick === 100) {
            clearInterval(timer);
            if(variantB)
                clearInterval(slideTimer);
            setTimeout(async () => {

            let requestMap = {};
            requestMap["platform"] = "web";
            answers.forEach((value, key) => {
            requestMap[key] = value
            });

                // send onboarding answers to server
                await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/create_registration_statistics", {
                            method: "POST",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(
                            requestMap
                            ),
                        }).then(async (response) => {
                            console.log("create statistic record result");
                            let resultBody = response.json();
                            await resultBody.then( async (json) => {
                                localStorage.setItem('onboarding_id', json.body.id);
                            });
                        });
                navigate("/paywall");
                amplitude.getInstance().logEvent('onboarding_finish');
            }, 3000)
        }
    }, [tick])

    useEffect(() => {
        if(!variantB) return;

        setSlideTimer(setInterval(() => { goNext() }, 1900));
        return () => {
            clearInterval(slideTimer);
        }
    }, [slide])

    const goNext = () => {
        if(!variantB) return;

        setSlide(slide + 1)
        if(slide === content.length - 1) {
            setSlide(0);
        }
    }
    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <ResultHeader url={'/images/result/plan-result.jpg'}/>
            <h1>Creating your plan...</h1>
            {
                variantB ? (
                    <>
                        <div className={`${styles.circleContainer} ${styles.smallCircleContainer}`}>
                            <p className={styles.circlePercentage}>{tick}%</p>
                            <svg>
                                <defs>
                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                        <stop offset="0%" stopColor="#DD3787" />
                                        <stop offset="100%" stopColor="#FD6C59" />
                                    </linearGradient>
                                </defs>
                                <circle cx="80" cy="80" r="80" stroke="url(#gradient)" strokeDashoffset={`calc(500px - (500px * ${tick}) / 100)`}></circle>
                            </svg>
                        </div>
                        <div className={styles.textContainer}>
                            { (tick >= 0 && tick < 33) && (<p className={styles.active}>Determining your level</p>) }
                            { (tick >= 33 && tick < 66) && (<p className={styles.active}>Analyzing your goals</p>) }
                            { (tick >= 66 && tick < 100) && (<p className={styles.active}>Adapting the program to your lifestyle</p>) }
                            { (tick === 100) && (<p className={styles.active}>Your program is ready!</p>) }
                        </div>
                        <div className={styles.infoContainer}>
                            <div className={styles.infoLine}></div>
                            <div className={styles.infoTitle}>
                                1 million people
                            </div>
                            <div className={styles.infoSubtitle}>
                                have chosen Everdance
                            </div>
                        </div>
                        <div className={styles.commentsContainer}>
                            {
                                content.map((comment, i) => (
                                    <div key={comment.id} className={`${i === slide ? styles.active : ''} ${styles.commentContainer}`}>
                                        <div className={styles.titleContainer}>
                                            <h2>{comment.title}</h2>
                                            <img src="/images/paywall/stars-image.jpg" alt="" />
                                        </div>
                                        <p>{comment.subtitle}</p>
                                        <div className={`${styles.authorContainer} flex-between`}>
                                            <span>{comment.author}</span>
                                            <span>{comment.date}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.circleContainer}>
                            <p className={styles.circlePercentage}>{tick}%</p>
                            <svg>
                                <defs>
                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                        <stop offset="0%" stopColor="#DD3787" />
                                        <stop offset="100%" stopColor="#FD6C59" />
                                    </linearGradient>
                                </defs>
                                <circle cx="137.5" cy="137.5" r="137.5" stroke="url(#gradient)" strokeDashoffset={`calc(860px - (860px * ${tick}) / 100)`}></circle>
                            </svg>
                        </div>
                        <div className={styles.textContainer}>
                            <p className={tick >= 25 ? styles.active : ''}>Determining your level</p>
                            <p className={tick >= 50 ? styles.active : ''}>Analyzing your goals</p>
                            <p className={tick >= 75 ? styles.active : ''}>Adapting the program to your lifestyle</p>
                            <p className={tick === 100 ? styles.active : ''}>Your program is ready!</p>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default PlanResult;