import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import ReactPixel from 'react-facebook-pixel';
import sha256 from 'crypto-js/sha256';
import CheckoutForm from './CheckoutForm';
import amplitude from 'amplitude-js';
import moment from 'moment/moment';
import styles from "./Payment.module.scss";

const token = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';
const testKey = 'pk_test_51IQytoJwa78Nc1Fj4yxM9tCXN6KDwzHcUv8uPpTSjK2R3BU1FxGlt10NbZRVyEsJULrLgXfDo2xp93kKckNixPxU00T0qPsZmh'
const liveKey = 'pk_live_51IQytoJwa78Nc1FjgA5V2BohKo1lacqu7RsI0EoGl5XMcf4BexV34obBDCkwUF8qQtD3L298Bkc0XoLyPv8rbt5n00PLj9RnLf'

import React from 'react';
import WaitingRegistrationPopup from "../../components/UI/waitingRegistrationPopup/WaitingRegistrationPopup";
// import { RecurlyProvider, Elements } from '@recurly/react-recurly';
// import { RecurlyPaymentForm } from './RecurlyPaymentForm';

const Payment = () => {
    const liveMode = localStorage.getItem('liveMode') === "true"
    const [stripePromise, setStripePromise] = useState(null);
    const [currentPeriod, setCurrentPeriod] = useState(JSON.parse(localStorage.getItem('period')));
    const [displayWaitingRegistrationPopup, setDisplayWaitingRegistrationPopup] = useState(false)
    // const [activePaymentMethod, setActivePaymentMethod] = useState('cards')
    const discountStatus = localStorage.getItem('discountStatus');
    const location = localStorage.getItem('location');
    const navigate = useNavigate();
    // const [finishStatus, setfinishStatus] = useState(false);
    // const [isOpen, setOpen] = useState(false);
    let buttonText = discountStatus === 'trial' ? 'Get 1 week for $1.00' : 'Continue'

    useEffect(() => {
      setDisplayWaitingRegistrationPopup(false)
      const eventId = `checkout.${getCookie('id')}`;
      ReactPixel.track('InitiateCheckout', {eventID: eventId});
      const payload = {
        data: [
          {
            event_name: "InitiateCheckout",
            event_time: moment().unix(),
            action_source: "website",
            event_id: eventId,
            user_data: {
                client_user_agent: navigator.userAgent,
                external_id: sha256(getCookie('id')).toString()
            }
          }
        ]
      };
      const fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]).join();
      const fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]).join();

      if(fbc) {
        payload.data[0].user_data.fbc = fbc;
      }

      if(fbp) {
        payload.data[0].user_data.fbp = fbp;
      }

      fetch(`https://graph.facebook.com/v10.0/239948538703054/events?access_token=${token}`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
    }, [])

    function getCookie(name) {
      const matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    useEffect(() => {
        setDisplayWaitingRegistrationPopup(false)
        setStripePromise(loadStripe(liveMode ? liveKey : testKey));
        amplitude.getInstance().logEvent('screen_onboarding_payment_appeared');
    }, []);

    const closePayment = () => {
        if(location !== 'k') {
            localStorage.setItem('discountStatus', 'discount');
        }
        navigate('/paywall');
        amplitude.getInstance().logEvent('button_onboarding_payment_close_tapped');
    }

  return (
    <>
      <div className={`${styles.mainContainer} flex-column`}>
        { displayWaitingRegistrationPopup ? (<WaitingRegistrationPopup />) : null }
        <img className={styles.closeIcon} src="/images/icons/close-icon.jpg" alt="" onClick={closePayment}/>

        <h1 className={styles.title}>Select payment method</h1>
        { discountStatus !== 'trial' ?
            (<p className={styles.subtitle}>Start your <b>{currentPeriod?.title}</b> <br/>${currentPeriod?.newPerDay} per day</p>) :
            (<p className={styles.subtitle}>Start your <b>7-day trial</b> just for $1 <br/> then ${currentPeriod?.total}/{currentPeriod?.paymentInfo.period}</p>)
        }

        {/*<div className={`${styles.paymentContainer} flex-between`}>*/}
        {/*  <div className={`${styles.paymentMethod} ${activePaymentMethod === 'applepay' ? styles.active : ''} ${styles.applePay}`} onClick={() => selectPaymentMethod('applepay')}>*/}
        {/*    <img className={styles.applePay} src="/images/applePay-icon.png" alt="" />*/}
        {/*    <p>Pay</p>*/}
        {/*  </div>*/}
        {/*  <div className={`${styles.paymentMethod} ${activePaymentMethod === 'cards' ? styles.active : ''}`} onClick={() => selectPaymentMethod('cards')}>*/}
        {/*    <div className={styles.cardsContainer}>*/}
        {/*      <p>Credit card</p>*/}
        {/*      <img className={styles.cards} src="/images/cardsIcon.png" alt="" />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className={`${styles.paymentMethod} ${activePaymentMethod === 'paypal' ? styles.active : ''}`} onClick={() => selectPaymentMethod('paypal')}>*/}
        {/*    <img className={styles.payPal} src="/images/PayPal-icon.png" alt="" />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className={styles.underline}></div>*/}
        {/*{*/}
        {/*  currentPeriod?.paymentInfo.title !== '7-day' ? (*/}
        {/*    <>*/}
        {/*      <div className={`${styles.countContainer} flex-between`}>*/}
        {/*        <p>{currentPeriod?.paymentInfo.title} subscription</p>*/}
        {/*        <p>{currentPeriod?.paymentInfo.oldPrice} USD</p>*/}
        {/*      </div>*/}
        {/*      <div className={`${styles.countContainer} flex-between`}>*/}
        {/*        <p>{currentPeriod?.paymentInfo.priceDiscountPercentage} price discount</p>*/}
        {/*        <p>-{currentPeriod?.paymentInfo.priceDiscount} USD</p>*/}
        {/*      </div>*/}
        {/*      <div className={styles.underline}></div>*/}
        {/*    </>*/}
        {/*  ) : null*/}
        {/*}*/}
        {/*<div className={`${styles.totalContainer} flex-between`}>*/}
        {/*  <h2>Total:</h2>*/}
        {/*  <p><span>{currentPeriod?.total} USD</span>{currentPeriod?.paymentInfo.period}</p>*/}
        {/*</div>*/}
        {/*<span className={styles.description}>You agree that {currentPeriod?.total} USD will be charged and automatically billed every month. If you don’t want to be billed, you may cancel your subscription via support everdance.supp@gmail.com</span>*/}


        {stripePromise && (
        // <RecurlyProvider publicKey="ewr1-gKu7rjEIZjGE6szIyJsIjq">
        //                  <Elements>
        //                    <RecurlyPaymentForm period={currentPeriod} />
        //                  </Elements>
        //  </RecurlyProvider>
            <Elements stripe={stripePromise}>
                <CheckoutForm period={currentPeriod} buttonText={buttonText} setWaitingPopup={setDisplayWaitingRegistrationPopup}/>
            </Elements>
        )}
      </div>
    </>
  );
}

export default Payment;